let Environment = {
  chinaTomi: "0xaDF3202480bD62F3dB150E27A0F811f999F253B9", 
  leia: "0x3aEdC9075A8238A68BEc28495ea566f9abcA2840",
  GemsLocking: "0x6A1aa5ac35f38Bdb25BBD976E8eca942b23260b2",
  GemsStaking: "0x03B830791ebb7c70C47936A57cDf02D13ff41168",
  GemsToken: "0x3010ccb5419F1EF26D40a7cd3F0d707a0fa127Dc",
  StackContact: "0xF5c9F83C14db63ab8260a8d19d0B3eC8B1903E92",
  nfa: "0x8c61465F51C95635a43E4D0094f2329Ff5989992",
};
export default Environment;
