import React, { useEffect, useState } from 'react'
import "./banner.scss"
import Information from '../Information/Information'
import Media from '../Media/Media'
import LatestTweet from '../LatestTweet/LatestTweet'
import Document from '../Documents/Document'
import { useTranslation } from 'react-i18next'
import CuurentRoundl from "../../../hooks/DataFetchers/roundl";
import Timerl from "../../../hooks/DataFetchers/timel.js";
import axios from "axios";
const Banner = () => {
  const { t } = useTranslation()



  const { cuurentRoundl } = CuurentRoundl()
  const { timerl } = Timerl()
  const [currentRount, setCurrentRount] = useState(null)
  const [currentRoundd, setCurrentRoundd] = useState(null)
  const [niceName, setNicName] = useState(null)
  const [price, setPrice] = useState(null)
  var now11 = new Date();
  let createdDate = now11?.getTime()
  // console.log(createdDate,'createdDate');
  let currentTime = createdDate / 1000;
  currentTime = parseInt(currentTime)
  const firstTimeData = async () => {
    try {
      let b = await cuurentRoundl()

      if (b) {
        // setCurrentRoundd(b)
        roundData(b)
      }
    } catch (err) {
      console.log(err.message);

    }
  };

  const roundData = async (e) => {
    // console.log(e,'eee');
    try {
      if (parseInt(e) <= 1) {
        // console.log('in if noor ',e);
        let c = await timerl(e)

        if (c) {
          setCurrentRount(c)
          // setPrice(e?.price/10**18)
          setCurrentRoundd(e)
          userLogin1(e)

          setTimeout(() => {
            // console.log("Delayed for 1 second.");
          }, "1000");
          // setPrice(c.price/10**18)
          // console.log('nooor',c.price);
          // setCurrentTimer(c)
        }
      }
      else {
        let dummy = []
        // console.log(e,'loooop bahis');
        for (let a = 1; a <= e; a++) {
          // console.log(a,'loooop');
          let c = await timerl(a)
          dummy.push(c)

        }
        // console.log(dummy,'loooop');
        calucation(dummy)


      }
    } catch (err) {
      console.log(err, 'erro in timer');
    }
  }

  const calucation = (e) => {
    // console.log(e,'imad');
    let dummy = []
    let a = ''
    let indexx = ''
    let aaa = e.map((i, index) => {
      let tempLockedLending2 = ''
      if (currentTime > parseInt(i.startTime) && currentTime < parseInt(i.endTime)) {
        a = i
        indexx = index + 1
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)

      } else {
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)
      }

    })
    if (a) {
      setPrice(a?.price / 10 ** 18)
      setCurrentRount(a)
      setCurrentRoundd(indexx)
      userLogin1(indexx)
      // setPrice(value?.price/10**18)

    }
    else if (dummy?.length > 0) {
      dummy.sort((a, b) => {
        return a?.diffrence - b?.diffrence
      })
      setCurrentRount(dummy[0])
      setCurrentRoundd(dummy[0]?.currentround)
      userLogin1(dummy[0]?.currentround)
      setPrice(dummy[0]?.price / 10 ** 18)
    }

  }
  const userLogin1 = async (e) => {
    // setLoarder(true);
    await axios
      .get("https://prod-api.gems.vip/sale-rounds/users")
      .then(async (res) => {
        // setLoarder(false);
        let a = res.data.data
        a.map((i) => {
          if (e == i?.round && i.privateSale == "OLYMPUS") {
            setNicName(i.nickName)

          }
        })
        setTimeout(() => {
          // console.log("Delayed for 1 second.");
        }, "1000");
        // calucation(res.data.data)
        // console.log(res.data.data,'res.data.data');
      })
      .catch((err) => {
        console.log(err, "err");
        // setLoarder(false);
      });
  };




  useEffect(() => {
    firstTimeData()
  }, [])

  const [timeshow, setTimeshow] = useState(false);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      let now = ''
      let time = ''
      let diff = ''
      if (currentTime < new Date(parseInt(currentRount?.startTime))) {
        now = new Date();
        time = new Date(parseInt(currentRount?.startTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow(false)
      } else if (currentTime > new Date(parseInt(currentRount?.startTime)) && currentTime < new Date(parseInt(currentRount?.endTime))) {
        now = new Date();
        time = new Date(parseInt(currentRount?.endTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow(false)
        // console.log(diff);
      }
      if (diff <= 0) {
        if (currentTime < new Date(parseInt(currentRount?.startTime))) {

          window.location.reload()
        }

        setTimeshow(true)

        clearInterval(interval);
      }
      const days = Math.floor(diff / (60 * 60 * 24));
      const hours = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
      const mins = Math.floor((diff % (60 * 60)) / (60));
      const secs = Math.floor((diff % (60)));
      setDay(days);
      setHour(hours);
      setMin(mins);
      setSec(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, [currentRount]);
  return (
    <>
      <section className="banner-gemsdetail banner-gemsdetailfordopmobile leaiadmindetailpage">
        <img src="\assets\detail-page\leia-banner-bg.png" alt="img" className='img-fluid banner-bg' />
        {/* <img src="\assets\detail-page\banner-bg-mbl.png" alt="img" className='img-fluid d-none banner-bg-mbl' /> */}
        <div className="custom-container h-100">
          <div className="parent">
            <div className="upper-content">
              <div className="main-logo">
                <img src="\assets\detail-page\leiadetailpage-logo.svg" alt="img" className='img-fluid' />
              </div>
              <div className="main-text">
                <h4>LEIA</h4>
                <p>$LEIA</p>
                <div className="social-icons">
                  <a href="https://www.leia.games/" target='_blank'><img src="\assets\detail-page\social-icons\global.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://x.com/Leia_Games" target='_blank'><img src="\assets\detail-page\social-icons\twitter.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://t.me/LeiaGames" target='_blank'><img src="\assets\detail-page\social-icons\telegram.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://www.linkedin.com/company/leia-games/" target='_blank'><img src="\assets\detail-page\social-icons\linkedin.svg" alt="img" className='img-fluid' /></a>

                </div>
              </div>
            </div>
            {/* <div className="mid-content">
                            <div className="single-text">
                                <p>Token Price</p>
                                <h6>$0.08</h6>
                            </div>
                            <div className="single-text">
                                <p>Token Supply</p>
                                <h6>23,370,186,853</h6>
                            </div>
                            <div className="single-text">
                                <p>Hard Cap per User</p>
                                <h6>1,500,000</h6>
                            </div>
                        </div> */}
            <div className="gems-buy-presale d-none">
              <div className="presale-box">

                <div className="bottom-box">
                  <h6 className='top-head'>{t('Gems-detailt5')}</h6>
                  <div className="mid-text">
                    <div className="text">
                      <p>{t('Gems-detailt6')}</p>
                     
                        <h6 style={{ color: "#FE0000" }}>{t("Dop-detail3")}</h6>

                      
                    </div>
                    <div className="text">
                      <p>{t('Gems-detailt7')}</p>
                      <h6>--</h6>
                    </div>
                    <div className="text">
                      <p>{t('Gems-detailt8')}</p>
                      <h6>--</h6>
                    </div>
                    {/* <div className="text">
                                            <p>{t('Dop-detail3')}</p>
                                            <h6>--</h6>
                                        </div> */}
                  </div>
                  <a href="https://leia.gems.vip" target="_blank" className={!timeshow && currentTime > new Date(parseInt(currentRount?.startTime)) && currentTime < new Date(parseInt(currentRount?.endTime)) ? "btn-buy disablefornow" : "btn-buy disablefornow"}>{t('Btn-buynow')} <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M6.86309 2.02948V3.47413H8.30776V2.02948H6.86309ZM8.30776 3.47413V4.91809H9.75171V3.47413H8.30776ZM9.75171 4.91809V6.36274H0.831543V7.8046H9.75171V9.24854H11.1964V7.8046H12.9999V6.36274H11.1964V4.91809H9.75171ZM9.75171 9.24854H8.30776V10.6932H9.75171V9.24854ZM8.30776 10.6932H6.86309V12.1372H8.30776V10.6932Z" fill="black" />
                  </svg></a>
                </div>
              </div>

            </div>
            <ul class="nav nav-tabs banner-tabs" id="myTab" role="tablist" style={{ justifyContent: "center" }}>
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">{t('Gems-detailt2')}</button>
              </li>
              {/* <li class="nav-item" role="presentation">
                                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">{t('Gems-detailt3')}</button>
                            </li> */}
              {/* <li class="nav-item" role="presentation">
                                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Latest Tweets</button>
                            </li> */}
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="document-tab" data-bs-toggle="tab" data-bs-target="#document-tab-pane" type="button" role="tab" aria-controls="document-tab-pane" aria-selected="false">{t('Gems-detailt4')}</button>
              </li>

            </ul>
          </div>
        </div>
      </section>



      <section className="bottom-new-section">
        <div className="parent">
          <div className="left-side">
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0"><Information /></div>
              {/* <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0"><Media /></div>
                            <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0"><LatestTweet /></div> */}
              <div class="tab-pane fade" id="document-tab-pane" role="tabpanel" aria-labelledby="document-tab" tabindex="0"><Document /></div>
            </div>
          </div>
          <div className="right-side displaynoneinmobile">
            <div className="presale-box">
              {/* {!timeshow ? */}
              <div className="bottom-box">
                <h6 className='top-head'>{t('Gems-detailt5')}</h6>
                <div className="mid-text">
                  <div className="text">
                    <p>{t('Gems-detailt6')}</p>
                   
                      <h6 style={{ color: "#FE0000" }}>{t("Dop-detail3")}</h6>

                  </div>
                  <div className="text">
                    <p>{t('Gems-detailt7')}</p>
                    <h6>-- </h6>
                  </div>
                  <div className="text">
                    <p>{t('Gems-detailt8')}</p>
                    <h6>--</h6>
                  </div>
                  {/* <div className="text">
                                            <p>{t('Dop-detail3')}</p>
                                            <h6>--</h6>
                                        </div> */}
                </div>
                <a href="https://leia.gems.vip" target="_blank" className={!timeshow && currentTime > new Date(parseInt(currentRount?.startTime)) && currentTime < new Date(parseInt(currentRount?.endTime)) ? "btn-buy disablefornow" : "btn-buy disablefornow"}>{t('Btn-buynow')} <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path d="M6.86309 2.02948V3.47413H8.30776V2.02948H6.86309ZM8.30776 3.47413V4.91809H9.75171V3.47413H8.30776ZM9.75171 4.91809V6.36274H0.831543V7.8046H9.75171V9.24854H11.1964V7.8046H12.9999V6.36274H11.1964V4.91809H9.75171ZM9.75171 9.24854H8.30776V10.6932H9.75171V9.24854ZM8.30776 10.6932H6.86309V12.1372H8.30776V10.6932Z" fill="black" />
                </svg></a>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default Banner
