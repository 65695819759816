// import logo from './logo.svg';
import "../../App.scss";
import React, { useState, useEffect, useRef } from "react";
import Banner from "./main-banner/Banner.js";
import Navbar from "./header/Navbar";
import Result from "./Results/Result.js";
import Era from "./NewEra/Era.js";
import Twice from "./Twice/Twice.js";
import Process from "./Process/Process.js";
import Where from "./Where/Where.js";
import How from "./HowItWorks/How.js";
import Stats from "./GemsTokenStats/Stats.js";
import GetBalance from "../../hooks/DataFetchers/getBalance";
import CuurentRound from "../../hooks/DataFetchers/round";
import Timer from "../../hooks/DataFetchers/time.js";
import axios from "axios";


function Landing({gemsData}) {
  const { GetBal } = GetBalance();
  const { cuurentRound } = CuurentRound()
  const { timer } = Timer()
  const [currentRount, setCurrentRount] = useState(null)
  const [currentRoundd, setCurrentRoundd] = useState(null)
  const [niceName, setNicName] = useState(null)
  var now11 = new Date();
  let createdDate = now11?.getTime()
  // console.log(createdDate,'createdDate');
  let currentTime = createdDate / 1000;
  currentTime = parseInt(currentTime)
  const firstTimeData = async () => {
    try {
      let b = await cuurentRound()

      if (b) {
        // setCurrentRoundd(b)
        roundData(b)
      }
    } catch (err) {
      console.log(err.message);
 
    }
  };

  const roundData = async (e) => {
    // console.log(e,'eee');
    try {
      if (parseInt(e) <= 1) {
        // console.log('in if noor ',e);
        let c = await timer(e)

        if (c) {
          setCurrentRount(c)
          // setPrice(e?.price/10**18)
          setCurrentRoundd(e)
          userLogin1(e)

          setTimeout(() => {
            // console.log("Delayed for 1 second.");
          }, "1000");
          // setPrice(c.price/10**18)
          // console.log('nooor',c.price);
          // setCurrentTimer(c)
        }
      }
      else {
        let dummy = []
        // console.log(e,'loooop bahis');
        for (let a = 1; a <= e; a++) {
          // console.log(a,'loooop');
          let c = await timer(a)
          dummy.push(c)

        }
        // console.log(dummy,'loooop');
        calucation(dummy)


      }
    } catch (err) {
      console.log(err, 'erro in timer');
    }
  }

  const calucation = (e) => {
    // console.log(e,'imad');
    let dummy = []
    let a = ''
    let indexx = ''
    let aaa = e.map((i, index) => {
      let tempLockedLending2 = ''
      if (currentTime > parseInt(i.startTime) && currentTime < parseInt(i.endTime)) {
        a = i
        indexx = index + 1
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)

      } else {
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)
      }

    })
    if (a) {
      // setPrice(a?.price/10**18)
      setCurrentRount(a)
      setCurrentRoundd(indexx)
      userLogin1(indexx)
      // setPrice(value?.price/10**18)

    }
    else if (dummy?.length > 0) {
      dummy.sort((a, b) => {
        return a?.diffrence - b?.diffrence
      })
      setCurrentRount(dummy[0])
      setCurrentRoundd(dummy[0]?.currentround)
      userLogin1(dummy[0]?.currentround)
      // setPrice(dummy[0]?.price/10**18)
    }

  }
  const userLogin1 = async (e) => {
    // setLoarder(true);
    await axios
      .get("https://prod-api.gems.vip/sale-rounds/users")
      .then(async (res) => {
        // setLoarder(false);
        let a = res.data.data
        a.map((i) => {
          if (e == i?.round && i.privateSale == "OLYMPUS") {
            setNicName(i.nickName)

          }
        })
        setTimeout(() => {
          // console.log("Delayed for 1 second.");
        }, "1000");
        // calucation(res.data.data)
        // console.log(res.data.data,'res.data.data');
      })
      .catch((err) => {
        console.log(err, "err");
        // setLoarder(false);
      });
  };
  
   
  useEffect(() => {
    console.log('sadasdadsad');
    document.body.classList.add('landing-body-class');

    return () => {
      document.body.classList.remove('landing-body-class');
    };
  }, [])
  return (
    <>
      <div className="set-parent-bannertwice">
        <img src="\assets\landing\banner-bg.png" alt="img" className="img-fluid banner-bg" />
        <img src="\assets\landing\banner-bg-mbl.png" alt="img" className="img-fluid banner-bg-mbll d-none" />
        <Banner
        />
        <Process />
      </div>
      <div>
        <Result />
      </div>
      <Era />
      <Stats gemsData={gemsData} />
      <Twice />
      {/* <How /> */}
      <Where />
    </>
  );
}

export default Landing;
