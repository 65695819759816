import React from "react";
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client'
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store/index";
import { Web3ReactProvider } from "@web3-react/core"
import { hooks as walletConnectV2Hooks, walletConnectV2 } from './connectors/walletConnectV2.js'
import { hooks as metaMaskHooks, metaMask } from './connectors/metaMask'
import i18next from 'i18next'
import 'flag-icon-css/css/flag-icon.min.css'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
const domNode = document.getElementById('root');
const root = createRoot(domNode);

const connectors = [
  [walletConnectV2, walletConnectV2Hooks],
  [metaMask, metaMaskHooks]
]
var languageselected = localStorage.getItem('codelanguage')

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'jp', 'es','tr'],
    lng: languageselected ? languageselected : 'en',
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })
const loadingMarkup = (
  <div className="py-4 text-center">
    <h3></h3>
  </div>
)

root.render(
  <Suspense fallback={loadingMarkup} >
    <Provider store={store}>
      <Web3ReactProvider connectors={connectors}>
        <App />
      </Web3ReactProvider>
    </Provider>
  </Suspense>
  ,


  // document.getElementById("root")
);