import React, { useEffect, useState } from "react";
import "./process.scss";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import CuurentRound from "../../../hooks/DataFetchers/round";
import Timer from "../../../hooks/DataFetchers/time.js";
import CuurentRoundl from "../../../hooks/DataFetchers/roundl.js";
import Timerl from "../../../hooks/DataFetchers/timel.js"
import CuurentRound2 from "../../../hooks/DataFetchers/round2.js";
import Timer2 from "../../../hooks/DataFetchers/time2.js";
import axios from "axios";

function SampleNextArrow(props) {
  const { className, onClick, hideArrow } = props;
  return !hideArrow ? (
    <div className={className} onClick={onClick}>
      <img
        src="/assets/landing/carousel-arrow-next.svg"
        alt="Next"
        className="img-fluid"
      />
    </div>
  ) : null; // Return null when you want to hide the arrow
}

function SamplePrevArrow(props) {
  const { className, onClick, hideArrow } = props;
  return !hideArrow ? (
    <div className={className} onClick={onClick}>
      <img
        src="/assets/landing/carousel-arrow-prev.svg"
        alt="Prev"
        className="img-fluid"
      />
    </div>
  ) : null; // Return null when you want to hide the arrow
}

const Process = () => {
  const { cuurentRound } = CuurentRound()
  const { timer } = Timer()
  const [currentRount, setCurrentRount] = useState(null)
  const [currentRoundd, setCurrentRoundd] = useState(null)
  const [niceName, setNicName] = useState(null)
  const [price, setPrice] = useState(null)
  var now11 = new Date();
  let createdDate = now11?.getTime()
  let currentTime = createdDate / 1000;
  currentTime = parseInt(currentTime)
  const firstTimeData = async () => {
    try {
      let b = await cuurentRound()
      if (b) {
        roundData(b)
      }
    } catch (err) {
      console.log(err.message);

    }
  };
  const roundData = async (e) => {
    try {
      if (parseInt(e) <= 1) {
        let c = await timer(e)
        if (c) {
          setCurrentRount(c)
          setCurrentRoundd(e)
          userLogin1(e)

          setTimeout(() => {
          }, "1000");
        }
      }
      else {
        let dummy = []
        for (let a = 1; a <= e; a++) {
          let c = await timer(a)
          dummy.push(c)

        }
        calucation(dummy)
      }
    } catch (err) {
      console.log(err, 'erro in timer');
    }
  }
  const calucation = (e) => {
    let dummy = []
    let a = ''
    let indexx = ''
    let aaa = e.map((i, index) => {
      let tempLockedLending2 = ''
      if (currentTime > parseInt(i.startTime) && currentTime < parseInt(i.endTime)) {
        a = i
        indexx = index + 1
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)

      } else {
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)
      }
    })
    if (a) {
      setPrice(a?.price / 10 ** 18)
      setCurrentRount(a)
      setCurrentRoundd(indexx)
      userLogin1(indexx)
    }
    else if (dummy?.length > 0) {
      dummy.sort((a, b) => {
        return a?.diffrence - b?.diffrence
      })
      setCurrentRount(dummy[0])
      setCurrentRoundd(dummy[0]?.currentround)
      userLogin1(dummy[0]?.currentround)
      setPrice(dummy[0]?.price / 10 ** 18)
    }

  }
  const userLogin1 = async (e) => {
    // setLoarder(true);
    await axios
      .get("https://prod-api.gems.vip/sale-rounds/users")
      .then(async (res) => {
        let a = res.data.data
        a.map((i) => {
          if (e == i?.round && i.privateSale == "OLYMPUS") {
            setNicName(i.nickName)

          }
        })
        setTimeout(() => {
        }, "1000");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    firstTimeData()
    firstTimeData1()
    firstTimeData2()
  }, [])
  const { t } = useTranslation();

  const [timeshow, setTimeshow] = useState(false);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      let now = ''
      let time = ''
      let diff = ''
      if (currentTime < new Date(parseInt(currentRount?.startTime))) {
        now = new Date();
        time = new Date(parseInt(currentRount?.startTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow(false)
      } else if (currentTime > new Date(parseInt(currentRount?.startTime)) && currentTime < new Date(parseInt(currentRount?.endTime))) {
        now = new Date();
        time = new Date(parseInt(currentRount?.endTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow(false)
        // console.log(diff);
      }
      if (diff <= 0) {
        if (currentTime < new Date(parseInt(currentRount?.startTime))) {

          window.location.reload()
        }

        setTimeshow(true)

        clearInterval(interval);
      }
      const days = Math.floor(diff / (60 * 60 * 24));
      const hours = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
      const mins = Math.floor((diff % (60 * 60)) / (60));
      const secs = Math.floor((diff % (60)));
      setDay(days);
      setHour(hours);
      setMin(mins);
      setSec(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, [currentRount]);;

  const { cuurentRoundl } = CuurentRoundl()
  const { timerl } = Timerl()
  const [currentRount1, setCurrentRount1] = useState(null)
  const [currentRoundd1, setCurrentRoundd1] = useState(null)
  const [niceName1, setNicName1] = useState(null)
  const [price1, setPrice1] = useState(null)
  const firstTimeData1 = async () => {
    try {
      let b = await cuurentRoundl()
      if (b) {
        roundData1(b)
      }
    } catch (err) {
      console.log(err.message);

    }
  }
  const roundData1 = async (e) => {
    try {
      if (parseInt(e) <= 1) {
        let c = await timerl(e)
        if (c) {
          setCurrentRount1(c)
          setCurrentRoundd1(e)
          userLogin11(e)

          setTimeout(() => {
          }, "1000");
        }
      }
      else {
        let dummy = []
        for (let a = 1; a <= e; a++) {
          let c = await timerl(a)
          dummy.push(c)

        }
        calucation1(dummy)
      }
    } catch (err) {
      console.log(err, 'erro in timer');
    }
  }
  const calucation1 = (e) => {
    let dummy = []
    let a = ''
    let indexx = ''
    let aaa = e.map((i, index) => {
      let tempLockedLending2 = ''
      if (currentTime > parseInt(i.startTime) && currentTime < parseInt(i.endTime)) {
        a = i
        indexx = index + 1
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)

      } else {
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)
      }
    })
    if (a) {
      setPrice1(a?.price / 10 ** 18)
      setCurrentRount1(a)
      setCurrentRoundd1(indexx)
      userLogin11(indexx)
    }
    else if (dummy?.length > 0) {
      dummy.sort((a, b) => {
        return a?.diffrence - b?.diffrence
      })
      setCurrentRount1(dummy[0])
      setCurrentRoundd1(dummy[0]?.currentround)
      userLogin11(dummy[0]?.currentround)
      setPrice1(dummy[0]?.price / 10 ** 18)
    }

  }
  const userLogin11 = async (e) => {
    // setLoarder(true);
    await axios
      .get("https://prod-api.gems.vip/sale-rounds/users")
      .then(async (res) => {
        let a = res.data.data
        a.map((i) => {
          if (e == i?.round && i.privateSale == "LEIA") {
            setNicName1(i.nickName)

          }
        })
        setTimeout(() => {
        }, "1000");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const [timeshow1, setTimeshow1] = useState(false);
  const [day1, setDay1] = useState(0);
  const [hour1, setHour1] = useState(0);
  const [min1, setMin1] = useState(0);
  const [sec1, setSec1] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      let now = ''
      let time = ''
      let diff = ''
      if (currentTime < new Date(parseInt(currentRount1?.startTime))) {
        now = new Date();
        time = new Date(parseInt(currentRount1?.startTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow(false)
      } else if (currentTime > new Date(parseInt(currentRount1?.startTime)) && currentTime < new Date(parseInt(currentRount1?.endTime))) {
        now = new Date();
        time = new Date(parseInt(currentRount1?.endTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow1(false)
        // console.log(diff);
      }
      if (diff <= 0) {
        if (currentTime < new Date(parseInt(currentRount1?.startTime))) {

          window.location.reload()
        }

        setTimeshow1(true)

        clearInterval(interval);
      }
      const days = Math.floor(diff / (60 * 60 * 24));
      const hours = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
      const mins = Math.floor((diff % (60 * 60)) / (60));
      const secs = Math.floor((diff % (60)));
      setDay1(days);
      setHour1(hours);
      setMin1(mins);
      setSec1(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, [currentRount1]);








  const { cuurentRound2 } = CuurentRound2()
  const { timer2 } = Timer2()
  const [currentRount2, setCurrentRount2] = useState(null)
  const [currentRoundd2, setCurrentRoundd2] = useState(null)
  const [niceName2, setNicName2] = useState(null)
  const [price2, setPrice2] = useState(null)
  const firstTimeData2 = async () => {
    try {
      let b = await cuurentRound2()
      if (b) {
        roundData2(b)
        console.log(b,'ssddsadsa');
      }
    } catch (err) {
      console.log(err.message,'ssddsadsa');

    }
  }
  const roundData2 = async (e) => {
    try {
      if (parseInt(e) <= 1) {
        let c = await timer2(e)
        if (c) {
          setCurrentRount2(c)
          setCurrentRoundd2(e)
          userLogin22(e)
          setPrice2(c?.price / 10 ** 18)
          setTimeout(() => {
          }, "1000");
        }
      }
      else {
        let dummy = []
        for (let a = 1; a <= e; a++) {
          let c = await timer2(a)
          dummy.push(c)

        }
        calucation2(dummy)
      }
    } catch (err) {
      console.log(err, 'erro in timer');
    }
  }
  const calucation2 = (e) => {
    let dummy = []
    let a = ''
    let indexx = ''
    let aaa = e.map((i, index) => {
      let tempLockedLending2 = ''
      if (currentTime > parseInt(i.startTime) && currentTime < parseInt(i.endTime)) {
        a = i
        indexx = index + 1
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)

      } else {
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)
      }
    })
    if (a) {
      setPrice2(a?.price / 10 ** 18)
      setCurrentRount2(a)
      setCurrentRoundd2(indexx)
      userLogin22(indexx)
    }
    else if (dummy?.length > 0) {
      dummy.sort((a, b) => {
        return a?.diffrence - b?.diffrence
      })
      setCurrentRount2(dummy[0])
      setCurrentRoundd2(dummy[0]?.currentround)
      userLogin22(dummy[0]?.currentround)
      setPrice2(dummy[0]?.price / 10 ** 18)
      
    }
  }
  const userLogin22 = async (e) => {
    // setLoarder(true);
    await axios
      .get("https://prod-api.gems.vip/sale-rounds/users")
      .then(async (res) => {
        let a = res.data.data
        a.map((i) => {
          if (e == i?.round && i.privateSale == "NFA") {
            setNicName2(i.nickName)

          }
        })
        setTimeout(() => {
        }, "1000");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const [timeshow2, setTimeshow2] = useState(false);
  const [day2, setDay2] = useState(0);
  const [hour2, setHour2] = useState(0);
  const [min2, setMin2] = useState(0);
  const [sec2, setSec2] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      let now = ''
      let time = ''
      let diff = ''
      if (currentTime < new Date(parseInt(currentRount2?.startTime))) {
        now = new Date();
        time = new Date(parseInt(currentRount2?.startTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow(false)
      } else if (currentTime > new Date(parseInt(currentRount2?.startTime)) && currentTime < new Date(parseInt(currentRount2?.endTime))) {
        now = new Date();
        time = new Date(parseInt(currentRount2?.endTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow2(false)
        // console.log(diff);
      }
      if (diff <= 0) {
        if (currentTime < new Date(parseInt(currentRount2?.startTime))) {

          window.location.reload()
        }

        setTimeshow2(true)

        clearInterval(interval);
      }
      const days = Math.floor(diff / (60 * 60 * 24));
      const hours = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
      const mins = Math.floor((diff % (60 * 60)) / (60));
      const secs = Math.floor((diff % (60)));
      setDay2(days);
      setHour2(hours);
      setMin2(mins);
      setSec2(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, [currentRount1]);










  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 2.2;

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4.2,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next),
    nextArrow: (
      <SampleNextArrow hideArrow={currentSlide >= totalSlides - 1} />
    ),
    prevArrow: <SamplePrevArrow hideArrow={currentSlide === 0} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="process-projects">
        <div className="custom-container padd-right-sm">
          {/* <div className="main-heading">
            <h6>{t('Process-t1')}</h6>
          </div> */}
          <div className="bottom-cards">
            <div className="slider">
              <Slider {...settings}>
                <div className="single-card order1inmobile activee">
                  <img src="\assets\landing\border-card-img.png" alt="img" className="img-fluid border-card-img" />
                  <Link to="/nfa">
                    <div className="upper-twice">
                      <div className="main-img">
                        <img
                          src="\assets\nfa\token-img.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="right-text">
                        <h6>NFA</h6>
                        {
                          currentTime < new Date(parseInt(currentRount2?.startTime)) ?
                            <p>{t('Process-t13')}</p> :
                            <>
                              {
                                currentTime > new Date(parseInt(currentRount2?.startTime)) && currentTime < new Date(parseInt(currentRount2?.endTime)) ?
                                  <p>{t('Process-t10')}</p>
                                  :
                                  ""
                              }
                            </>
                        }
                        {timeshow2 ?
                          <h5>{t('Process-t12')}</h5>
                          :
                          <h5>{day2 ? day2 : 0}D : {hour2 ? hour2 : 0}H : {min2 ? min2 : 0}M : {sec2 ? sec2 : 0}S</h5>
                        }

                        {/* <p>Private Sale</p>
                        <h5 style={{ color: "#17E6A1" }}>{t('Coming')}</h5> */}

                      </div>
                    </div>
                    <div className="bottom-twice">
                      <div className="inside-new-inline">
                        <div className="inner-text">
                          <p>{t('Process-t4')}</p>
                          <h6 style={{ textAlign: "start" }}>{currentTime > new Date(parseInt(currentRount2?.startTime)) || currentTime < new Date(parseInt(currentRount2?.endTime)) ? niceName2 : "--"}</h6>
                        </div>
                        <div className="inner-text">
                          <p>{t('Process-t3')}</p>
                          <h6>{currentTime > new Date(parseInt(currentRount2?.startTime)) || currentTime < new Date(parseInt(currentRount2?.endTime)) ? "$" + parseFloat(price2)?.toFixed(7) : "--"} </h6>
                        </div>
                      </div>
                      <div className="bottom-raised">
                        <p>TOTAL RAISED</p>
                        <h6>--</h6>
                      </div>
                    </div>
                  </Link>
                  {
                    !timeshow2 && currentTime > new Date(parseInt(currentRount2?.startTime)) && currentTime < new Date(parseInt(currentRount2?.endTime))
                      ?
                      <a
                        href="https://nfa.gems.vip/"
                        target="_blank"
                        className="btn-detail">
                        {t('Btn-buynow')}
                      </a>
                      :

                      <Link
                        to="/nfa"
                        className="btn-detail view-project-btn">
                        {t('Process-t7')}
                      </Link>

                  }
                </div>
                <div className="single-card order1inmobile activee">
                  <img src="\assets\landing\border-card-img.png" alt="img" className="img-fluid border-card-img" />
                  <Link to="/olympus">
                    <div className="upper-twice">
                      <div className="main-img">
                        <img
                          src="\assets\landing\omp-coin-new.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="right-text">
                        <h6>Olympus AI</h6>
                        {
                          currentTime < new Date(parseInt(currentRount?.startTime)) ?
                            <p>{t('Process-t13')}</p> :
                            <>
                              {
                                currentTime > new Date(parseInt(currentRount?.startTime)) && currentTime < new Date(parseInt(currentRount?.endTime)) ?
                                  <p>{t('Process-t10')}</p>
                                  :
                                  <p>{t('Process-t13')}</p>
                              }
                            </>
                        }
                        {timeshow ?
                          <h5>TBD</h5>
                          :
                          <h5>{day ? day : 0}D : {hour ? hour : 0}H : {min ? min : 0}M : {sec ? sec : 0}S</h5>
                        }
                      </div>
                    </div>
                    <div className="bottom-twice">
                      <div className="inside-new-inline">
                        <div className="inner-text">
                          <p>{t('Process-t4')}</p>
                          {/* <h6 style={{ textAlign: "start" }}>{currentTime > new Date(parseInt(currentRount?.startTime)) || currentTime < new Date(parseInt(currentRount?.endTime)) ? niceName : "--"}</h6> */}
                          <h6 style={{ textAlign: "start" }}>--</h6>

                        </div>
                        <div className="inner-text">
                          <p>{t('Process-t3')}</p>
                          {/* <h6>{currentTime > new Date(parseInt(currentRount?.startTime)) || currentTime < new Date(parseInt(currentRount?.endTime)) ? "$" + parseFloat(price)?.toFixed(7) : "--"} </h6> */}
                          <h6>-- </h6>

                        </div>
                      </div>
                      <div className="bottom-raised">
                        <p>TOTAL RAISED</p>
                        <h6>--</h6>
                      </div>
                    </div>
                  </Link>
                  {
                    !timeshow && currentTime > new Date(parseInt(currentRount?.startTime)) && currentTime < new Date(parseInt(currentRount?.endTime))
                      ?
                      <a
                        href="https://olympusai.gems.vip"
                        target="_blank"
                        className="btn-detail">
                        {t('Btn-buynow')}
                      </a>
                      :

                      <Link
                        to="/olympus"
                        className="btn-detail view-project-btn">
                        {t('Process-t7')}
                      </Link>

                  }
                </div>
                <div className="single-card order1inmobile">
                  <Link to="/leia">
                    <div className="upper-twice">
                      <div className="main-img">
                        <img
                          src="\assets\landing\leia-card.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="right-text">
                        <h6>LEIA</h6>
                        <p>{t('Process-t10')}</p>

                        <h5 style={{ color: "#FE0000" }}>{t('Dop-detail3')}</h5>
                      </div>
                    </div>
                    <div className="bottom-twice">
                      <div className="inside-new-inline">
                        <div className="inner-text">
                          <p>{t('Process-t4')}</p>
                          <h6 style={{ textAlign: "start" }}>--</h6>
                        </div>
                        <div className="inner-text">
                          <p>{t('Process-t3')}</p>
                          <h6>--</h6>
                        </div>
                      </div>
                      <div className="bottom-raised">
                        <p>TOTAL RAISED</p>
                        <h6>$3,300,000+</h6>
                      </div>
                    </div>
                  </Link>
                  {/* {
                    !timeshow1 && currentTime > new Date(parseInt(currentRount1?.startTime)) && currentTime < new Date(parseInt(currentRount1?.endTime)) ?

                      <a
                        href="https://leia.gems.vip"
                        target="_blank"
                        className="btn-detail">
                        {t('Btn-buynow')}
                      </a>
                      :

                      <Link
                        to="/leia"
                        className="btn-detail view-project-btn">
                        {t('Process-t7')}
                      </Link>
                  } */}
                  <Link
                    to="/leia"
                    className="btn-detail view-project-btn">
                    {t('Process-t7')}
                  </Link>
                </div>
                <div className="single-card order1inmobile">
                  <Link to="/incentiv">
                    <div className="upper-twice">
                      <div className="main-img">
                        <img
                          src="\assets\landing\incentive-img.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="right-text">
                        <h6>Incentiv</h6>
                        {/* {
                          !timeshow ? <p>{t('Process-t13')}</p> : <p>{t('Process-t10')}</p>
                        } */}
                        <p>{t('Process-t10')}</p>

                        <h5 style={{ color: "#FE0000" }}>{t('Dop-detail3')}</h5>

                        {/* {!timeshow ?
                          <h5>{day ? day : 0}D : {hour ? hour : 0}H : {min ? min : 0}M : {sec ? sec : 0}S</h5>
                          :
                          <h5>{day1 ? day1 : 0}D : {hour1 ? hour1 : 0}H : {min1 ? min1 : 0}M : {sec1 ? sec1 : 0}S</h5>
                        } */}
                      </div>
                    </div>
                    <div className="bottom-twice">
                      <div className="inside-new-inline">
                        <div className="inner-text">
                          <p>{t('Process-t4')}</p>
                          <h6 style={{ textAlign: "start" }}>--</h6>
                        </div>
                        <div className="inner-text">
                          <p>{t('Process-t3')}</p>
                          <h6>--</h6>
                        </div>
                      </div>
                      <div className="bottom-raised">
                        <p>TOTAL RAISED</p>
                        <h6>$25,000,000+</h6>
                      </div>
                    </div>
                  </Link>
                  {/* {
                    !timeshow ? <Link
                      to="/incentiv"
                      className="btn-detail">
                      {t('Process-t7')}
                    </Link> : <a
                      href="https://privatesale.gems.vip/"
                      target="_blank"
                      className="btn-detail">
                      {t('Btn-buynow')}
                    </a>
                  } */}
                  <Link
                    to="/incentiv"
                    className="btn-detail view-project-btn"
                  >
                    {t('Process-t7')}
                  </Link>
                </div>
                <div className="single-card order1inmobile">
                  <Link to="/gemsdetail">
                    <div className="upper-twice">
                      <div className="main-img">
                        <img
                          src="\assets\landing\gems-main-img.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="right-text">
                        <h6>GEMS</h6>
                        <p>{t('Process-t10')}</p>
                        <h5 style={{ color: "#FE0000" }}>{t('Dop-detail3')}</h5>
                      </div>
                    </div>
                    <div className="bottom-twice" style={{ justifyContent: "center" }}>
                      <div className="inside-new-inline">
                        <div className="inner-text">
                          <p>{t('Process-t4')}</p>
                          <h6 style={{ textAlign: "start" }}>--</h6>
                        </div>
                        <div className="inner-text">
                          <p>{t('Process-t3')}</p>
                          <h6>--</h6>
                        </div>
                      </div>
                      <div className="bottom-raised">
                        <p>{t('Totalrasied')}</p>
                        <h6>$10,000,000</h6>
                      </div>

                    </div>
                  </Link>
                  <Link
                    to="/gemsdetail"
                    className="btn-detail view-project-btn">
                    {t('Process-t7')}
                  </Link>
                </div>
                <div className="single-card order2inmobile">
                  <Link to="/dopdetail">
                    <div className="upper-twice">
                      <div className="main-img">
                        <img
                          src="\assets\landing\dop-main-img.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="right-text">
                        <h6>DOP</h6>
                        <p>{t('Process-t10')}</p>
                        <h5 style={{ color: "#FE0000" }}>{t('Dop-detail3')}</h5>
                      </div>
                    </div>
                    <div className="bottom-twice" style={{ justifyContent: "center" }}>
                      <div className="inside-new-inline">
                        <div className="inner-text">
                          <p>{t('Process-t4')}</p>
                          <h6 style={{ textAlign: "start" }}>--</h6>
                        </div>
                        <div className="inner-text">
                          <p>{t('Process-t3')}</p>
                          <h6>--</h6>
                        </div>
                      </div>
                      <div className="bottom-raised">
                        <p>{t('Totalrasied')}</p>
                        <h6>$160,000,000</h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/dopdetail" className="btn-detail view-project-btn">
                    {t('Process-t7')}
                  </Link>
                </div>
                {/* <div
                  className="single-card order3inmobile"
                  style={{ cursor: "not-allowed", pointerEvents: "none" }}>
                  <img
                    src="\assets\landing\coming-soon.svg"
                    alt="img"
                    className="img-fluid h-100 w-100"
                  />
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Process;
