import React, { useState } from 'react'
import "./info.scss"
import { Player } from "video-react";
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap';

const Information = () => {
    const { t } = useTranslation()

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{ margin: "0" }}>
                    <h2>{t('Dop-detail4')} <span></span></h2>
                    <p>NFA is transforming how crypto investors discover opportunities. Powered by advanced AI, NFA curates a personalized feed of investment calls from Key Opinion Leaders (KOLs) across social media. Whether it’s trading signals, staking opportunities, or airdrops, NFA delivers actionable insights in a simple swipe-based interface—making crypto discovery as intuitive as swiping right.
                    </p>
                    <p>Launching first as a Telegram Mini App, NFA meets users where they already are, ensuring accessibility and convenience. With plans to expand into web apps, Chrome extensions, and widgets, NFA is set to become an essential tool for navigating the fast-moving world of crypto investments.</p>
                </div>
                <div className="first-para">
                    <h2>What Makes NFA Unique? <span></span></h2>
                    <ol>
                        <li><span>AI-Powered Curation:</span>  NFA analyzes thousands of KOL calls daily, using real-time data harvesting and backtesting to deliver only high-potential opportunities.
                        </li>
                        <li><span>Noise-Free Insights:</span>  In a sea of endless crypto content, NFA acts as a filter, cutting through misinformation to present reliable, actionable insights.</li>
                        <li><span>Convenience:</span>  Launching on Telegram as a Mini App ensures seamless integration into existing crypto communities.</li>
                        <li><span>New Revenue Stream for KOLs:</span> By ranking KOLs based on the success of their calls, NFA incentivizes quality insights while creating new revenue opportunities for influencers.
                        </li>
                
                    </ol>
                </div>
                <div className="first-para">
                    <h2>NFA’s Go-to-Market Strategy <span></span></h2>
                    <ul>
                        <li><span>Telegram Mini App Launch:</span>  Rapid distribution through Telegram Mini Apps, where crypto communities are already active.
                        </li>
                        <li><span>Partnerships with KOLs:</span>  Leveraging top influencers to promote NFA and drive user acquisition.</li>
                        <li><span>Airdrop Campaigns:</span>  Engaging users with targeted airdrops to build an active community from day one.</li>
                        <li><span>Future Expansion:</span>  Embedding NFA as a widget on partner websites and integrating with other crypto apps and wallets to maximize reach.
                        </li>
                    </ul>
                </div>

                <div className="first-para">
                    <h2>Roadmap <span></span></h2>
                    <p>Q4 2024 – Platform Genesis</p>
                    <ul>
                        <li>Launch Telegram Mini App with core features.
                        </li>
                        <li>Initiate strategic user acquisition campaigns.</li>
                    </ul>
                    <p className='mt-4'>Q1 2025 – Token Launch & Expansion
                    </p>
                    <ul>
                        <li>Token Generation Event (TGE).
                        </li>
                        <li>Scale to 5 million active users.</li>
                        <li>Integrate real-money trading features.
                        </li>
                    </ul>
                    <p className='mt-4'>Q2 2025 – Web Platform Evolution
                    </p>
                    <ul>
                        <li>Launch comprehensive web application with cross-chain capabilities.

                        </li>
                        <li>Introduce advanced portfolio tools and enhanced AI-driven analysis features.</li>
                    </ul>
                </div>
               
                <div className="first-para">
                    <h2>The Team <span></span></h2>
                    {/* <p>Lorem ipsum dolor sit amet. Aut voluptatem explicabo aut fuga impedit nam nesciunt nesciunt ut quisquam recusandae. Ea porro voluptatem sed expedita earum ut labore architecto qui earum corporis.</p> */}
                    <div className="team-gems">
                        <div className="single-team">
                            <img src="\assets\nfa\team\moshe.jpg" alt="img" className='img-fluid' />
                            <h6>Moshe Cohen</h6>
                            <h5>Founder & CEO</h5>
                            <p>A seasoned entrepreneur with over a decade of experience scaling companies to $50M ARR and extensive expertise in blockchain innovation.</p>
                        </div>
                        {/* <div className="single-team">
                            <img src="\assets\nfa\team\bar.JPG" alt="img" className='img-fluid' />
                            <h6>Shahaf Bar Gefen </h6>
                            <h5>Chairman</h5>
                            <p>Renowned leader in blockchain strategy and business development.</p>
                        </div> */}
                        <div className="single-team">
                            <img src="\assets\nfa\team\yaniv.png" alt="img" className='img-fluid' style={{ objectPosition: "top" }} />
                            <h6>Yaniv Daddon  </h6>
                            <h5>CTO</h5>
                            <p>Former CTO at COTI with deep expertise in AI-powered network analysis and blockchain technologies.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\nfa\team\tamir web.JPG" alt="img" className='img-fluid' />
                            <h6>Tamir Mizrahi</h6>
                            <h5>Head of Business Development</h5>
                            <p>Crypto veteran since 2017 with proven experience driving growth for pioneering projects like COTI and CVI.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\nfa\team\bar.JPG" alt="img" className='img-fluid' />
                            <h6>Bar Faruhi </h6>
                            <h5>Head of Marketing</h5>
                            <p>Marketing expert with seven years in DeFi and blockchain community building.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\nfa\team\lout.jpg" alt="img" className='img-fluid' style={{ objectPosition: "50%" }} />
                            <h6>Lout Schampers</h6>
                            <h5>Marketing & Community Manager</h5>
                            <p>Specialist in Web3 growth strategies and community engagement.</p>
                        </div>



                    </div>

                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail31')} <span></span></h2>
                    <div className="twice-tokenomics">
                        <div className="tokenomic-img">
                            <img src="\assets\nfa\tokenomics-new.png" alt="img" className='img-fluid w-100' />
                        </div>
                    </div>
                </div>


            </section>

            <Modal className='image-preview distribution-modl' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='p-0'>
                    <img src="\assets\detail-page\olympus-distribution.png" alt="Selected" className="img-fluid w-100" />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Information
