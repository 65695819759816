import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { leiaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const Timerl = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.leia;
  const contract = leiaContract(tokenAddress, web3);


  const timerl = useCallback(
    async (e) => {
 
      try {
        const buy = await contract.methods.rounds(e).call()
        return buy;
      } catch (error) {
        throw error;
      }
    },
    [contract, account,web3]
  );
  return { timerl: timerl};
};
export default Timerl;